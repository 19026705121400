import Layout from "../relaunch/components/layouts/layout/layout"
import { Customers } from "../relaunch/components/layouts/adopters/adopters"

const seo = {
  title: "Adopters - Discover the Companies That Trust Our Products | Ory",
  description:
    "Discover the companies that trust Ory's authentication solutions. Join our satisfied customers and elevate your user security today.",
}

const PricingPage = () => {
  return (
    <Layout>
      <Customers />
    </Layout>
  )
}

export const Head = () => (
  <>
    <title>{seo.title}</title>
    <meta name="description" content={seo.description} />
    <meta property="og:title" content={seo.title} />
    <meta property="og:description" content={seo.description} />
    <meta property="og:type" content="website" />
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:author" content="ORY" />
    <meta property="twitter:title" content={seo.title} />
    <meta property="twitter:description" content={seo.description} />
  </>
)

export default PricingPage
