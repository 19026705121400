import classNames from "classnames"
import { Rocket } from "phosphor-react"
import Button from "../../freestanding/button/button"
import Container from "../../freestanding/container"
import Section from "../../freestanding/section"
import customersData from "./adopters-data"

type CustomerCardProps = {
  title: string
  logo: string
  quote?: {
    text: string
    author: string
    position?: string
    href?: string
  }
  caseStudyUrl?: string
}

const CustomerCard = ({
  title,
  logo,
  quote,
  caseStudyUrl,
}: CustomerCardProps) => {
  return (
    <div
      className={classNames(
        "flex flex-col text-2xl ring-1 ring-gray-200",
        quote && "row-span-2",
      )}
    >
      <div className="flex justify-center bg-gray-100 py-12 only:flex-1 only:py-20">
        <img
          className="invert-1 h-10 max-w-[60%] object-contain brightness-[0] grayscale invert-0"
          src={logo}
          alt={title}
          title={title}
        />
      </div>
      {(quote || caseStudyUrl) && (
        <div className="flex flex-1 flex-col justify-between gap-6 p-6">
          {quote && (
            <>
              <p className="mb-auto text-sm text-gray-600">{quote.text}</p>
              <div className="flex">
                <div className="text-sm">
                  <p className="font-semibold text-indigo-900">
                    {quote.author}
                  </p>
                  {quote.position && (
                    <span className="text-gray-600">{quote.position}</span>
                  )}
                </div>
              </div>
            </>
          )}
          {caseStudyUrl && (
            <div className="flex justify-center">
              <Button
                title={"Case Study"}
                to={caseStudyUrl}
                iconRight="arrow-right"
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const YourPlace = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 px-5 py-20 ring-1 ring-gray-200">
      <Rocket size={40} />
      <Button to="" title="Start today" iconRight="arrow-right" />
      <p className="text-center text-sm">
        Secure your apps with our trusted solutions and join the ranks of
        successful businesses that have already made the switch.
      </p>
    </div>
  )
}

export const Customers = () => {
  return (
    <Section>
      <Container>
        <div className="col-span-6 md:col-span-12 lg:col-span-8">
          <h1 className="dark:text-cyan-50 text-5xl font-semibold text-indigo-900">
            Meet Ory Adopters
          </h1>
          <p className="dark:text-gray-300 mt-4 text-lg text-gray-600">
            Join the growing number of companies that trust our products and
            services. We enable businesses of all sizes to provide secure
            solutions and protect their applications.
          </p>
        </div>
        <div className="col-span-full grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
          {customersData.map((i) => (
            <CustomerCard
              key={i.title}
              title={i.title}
              logo={i.image as any as string}
              quote={i.quote}
              caseStudyUrl={i.caseStudyUrl}
            />
          ))}
          {/* <YourPlace /> */}
        </div>
      </Container>
    </Section>
  )
}
